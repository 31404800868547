/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
body {
    height: 100vh;
    width: 100vw;
    background-color: #111;
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    color: white;
}
a {
    text-decoration: none;
    color: white;
  }
li {
    text-decoration: none;
}
ul {
    list-style-type: none;
}

i {
    color: #ffd700;
    font-size: 1.75rem;
}

a:visited{
    color: white;
}

h1{
    font-size: 2em;
}

p {
    font-size: 0.9rem;
    font-weight: 300;
}

.mat-dialog-container {
    padding: 18px !important;
}

.my-3 {
    margin-top: 1rem;
    margin-bottom: 1rem
    }
    
    .mt-3 {
        margin-top: 1rem;
    }
    
    .mb-3 {
        margin-bottom: 1rem
    }

    .mb-5 {
        margin-bottom: 3rem
    }
    
    .ml-5 {
        margin-left: 3rem;
    }
    
    .ml-1 {
        margin-left: 0.25rem;
    }

    .ml-4 {
        margin-left: 2rem;
    }

    .ml-auto, .mx-auto {
        margin-left: auto;
    }

    .mr-auto, .mx-auto {
        margin-right: auto;
    }
    
    .mr-1 {
        margin-right: 0.25rem;
    }

    .mb-auto {
        margin-bottom: auto;
    }

    .mt-auto {
        margin-top: auto;
    }
    
    .px-3 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .pt-3{
        padding-top: 1rem;
    }

    .pt-5{
        padding-top: 3rem;
    }

    .pb-4 {
        padding-bottom: 2rem;
    }

    .py-3 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .pb-3 {
        padding-bottom: 1rem;
    }

    .py-5 {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .mt-7 {
        margin-top: 5em !important;
    }
    
    .row {
        display: flex;
        flex-wrap: wrap;
    }
    
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
    }
    
    .z-depth-2 {
        box-shadow: 0 8px 17px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19)!important;
    }

    .px-auto {
        padding-left: auto;
        padding-right: auto;
    }
    
    .rounded {
        border-radius: 0.25rem !important;
    }

    .btn-rounded{
        border-radius: 0.5rem;
    }
    
    /* .mask {
        opacity: 0;
        transition: all 0.5s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-attachment: fixed;
    }
    */
    
    .rgba-white-slight {
        background-color: rgba(255,255,255,.1);
    }
    
    .btn {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
        padding: 0.84rem 2.14rem;
        font-size: 0.81rem;
        margin: 0.375rem;
        text-transform: uppercase;
        white-space: normal;
        word-wrap: break-word;
        line-height: 1.5;
        text-align: center;
        vertical-align: middle;
        font-weight: 400;
        border: 2px solid #ffbb33;
        color: #ffbb33;
        transition: ease-in-out 0.7s;
    }

    a:hover, .btn:hover{
        transition: ease-in-out 0.7s;
    }
    
    .waves-effect {
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        overflow: hidden;
        position: relative;
    }
    
    .offset-1 {
        margin-left: 8.33333%;
    }
    
    .col-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-lg {
        padding-left: 15px;
        padding-right: 15px;
    }
.text-center {
    text-align: center;
}


.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.navbar {
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-nav {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.full-width {
    width: 100%;
}

.navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
}

.mat-dialog-container {
    padding: 0;
    overflow: unset !important;
}

.cdk-overlay-pane {
    height: auto !important;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}
.img-fluid, .video-fluid {
    max-width: 100%;
    height: auto;
    }
    .offset-1 {
        margin-left: 8.3333333333%;
    }
    .col-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }
    .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (max-width: 737px) {
        .col-lg {
            flex-basis: unset;
        }
        .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
            min-width: unset;
            width: 100%;
        }
        .btn-rounded:hover {
            color: unset;
            background: unset;
        }

        a:hover, .btn:hover{
            transition: unset;
        }
    }